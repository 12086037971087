import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import "./UserProfileForm.css";

const UserProfileForm = ({ user }) => {
  // console.log(user);
  let UserData = JSON.parse(localStorage.getItem("userData"));
  // const UserPassword = JSON.parse(localStorage.getItem("ChangePassword"));
  const [contactCode, setContactCode] = useState(user[0].user.contact_code);
  const [spinner, setSpinner] = useState(false);
  const [phoneNumber, setContactNumber] = useState(user[0]?.user?.contact_number ? user[0]?.user?.contact_number : user[0]?.user?.phoneNumber);
  // const [phoneNumber, setContactNumber] = useState(
  //   contactCode + user[0].user.phoneNumber
  // );
  const [company, setCompany] = useState(user[0]?.user?.company ? user[0]?.user?.company : user[0]?.user?.companyName);
  const [phone, setPhone] = useState("");
  const [profilePicture, setProfilePicture] = useState(user[0].profilePic);
  const [profile, setProfile] = useState("");
  console.log(profilePicture);
  const handleFileChange = (file) => {
    setProfile(file);
    // setTimeout(() => {
    //   getDatas();
    // }, 1000);
  };

  const fileTypes = ["JPG", "PNG", "GIF"];
  useEffect(() => {
    if (profile.name !== undefined) {
      //console.log(profile);
      getPicture();
      async function getPicture() {
        try {
          var data = new FormData();
          data.append("file", profile);
          const s3Res = await fetch(
            `${API_PATH.UPLOAD_MEDIA}/${UserData.user.company_id.shortName}`,
            {
              method: "POST",
              headers: headers,
              body: data,
            }
          );
          const s3Result = await s3Res.json();
          if (s3Result) {
            // console.log(s3Result);
            setProfilePicture(s3Result.data.location);
            var pp = s3Result.data.location;
          }
        } catch (error) {
          // console.log("Not working", error);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  }, [profile]);

  // console.log(profilePicture);

  const headers = {
    "x-access-token": UserData.access_token,
  };

  // console.log(profile);

  const [UserDetails, setUserDetails] = useState({
    firstNmae: user[0]?.user?.name,
    email: user[0]?.user?.email,
    country: user[0]?.user?.country,
    phoneNumber: phoneNumber,
    company: company,
    role_id: user[0]?.user?.role_id?._id,
    password: "",
  });

  // console.log(UserDetails);
  // console.log(contactCode);
  // console.log(contactNumber);
  // console.log(profile);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...UserDetails, [name]: value };
    setUserDetails(list);
  };

  const handelSubmit = async () => {
    //console.log(pp);

    if (
      UserDetails?.firstNmae !== "" &&
      UserDetails?.email !== "" &&
      phoneNumber !== "" &&
      company !== ""
    ) {
      setSpinner(true);
      document.getElementById("profile").disabled = true;
      let payload = {
        firstNmae: UserDetails?.firstNmae,
        email: UserDetails?.email,
        contactCode: contactCode,
        phoneNumber: phoneNumber,
        country: UserDetails?.country,
        companyName: company,
        role_id: UserDetails?.role_id,
        profilePic: profilePicture,
      };

      try {
        const res = await fetch(
          `${API_PATH.USER_MANAGEMENT_UPDATE}/${UserData.user._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // console.log("rolesData", res.data);

            body: JSON.stringify(payload),
          }
        );

        const data = await res.json();

        if (data.success === true) {
          //console.log(res.data.message);
          setSpinner(false);
          document.getElementById("profile").disabled = false;
          delete payload.role_id;
          UserData.user = {
            ...UserData.user,
            ...payload,
          };
          // // console.log(data);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
          localStorage.setItem("userData", JSON.stringify(UserData));
          return toast.success("Profile updated sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          // setResponseMSG(data.message);
          // setModalToggle(true);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // console.log("Not working", error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      setSpinner(false);
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  if (Object.keys(user).length !== 0) {
    return (
      <div className="container rounded bg-white">
        <div className="row px-xl-2 justify-content-center align-items-center">
          <div className="col-md-8 border-right">
            <div className="d-flex justify-content-center align-items-center mt-3">
              {/* <img
                className="rounded-circle mt-5"
                width="150px"
                src={profilePicture}
              /> */}
              <div className="user-div-top">
                <figure className="">
                  <Avatar
                    style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
                    sx={{
                      bgcolor: blue[500],
                      height: "82px",
                      width: "82px",
                      fontSize: "50px",
                      margin: "auto",
                    }}
                  >
                    {UserData?.user?.name === undefined
                      ? UserData?.user?.name[0]?.toUpperCase()
                      : UserData?.user?.name[0]?.toUpperCase()}
                  </Avatar>
                </figure>
                <span className="font-weight-bold text-center">
                  {user[0]?.user?.name}
                </span>
              </div>
            </div>
            {/* <div className="d-flex flex-column">
              <img
                className="rounded-circle"
                width="150px"
                src={profilePicture}
              />
              <span className="font-weight-bold">{user[0].firstNmae}</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                label="Upload Image"
                classes="drop_area drop_zone"
                maxSize={1}
                types={fileTypes}
              />
            </div> */}
          </div>
          <div className=" col-sm-12 col-lg-10 col-xl-8">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label> Name *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="firstNmae"
                      placeholder="Johnson Willianson"
                      onChange={handleInputs}
                      value={UserDetails?.firstNmae}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Email Address *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="email"
                      placeholder="Enter email address"
                      onChange={handleInputs}
                      value={UserDetails?.email}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Contact Number *</label>
                    <PhoneInput
                      country={"ind"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={setContactNumber}
                      value={phoneNumber}
                    />

                    {/* <PhoneInput
                      country={"ind"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(e) => {
                        setContactNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      inputStyle={{
                        width: "282px",
                        height: "38px",
                      }}
                    /> */}

                    {/* <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"  
                      placeholder="Contact Number"
                      onChange={(e) => {
                        setContactNumber(e.target.value);
                      }}
                      value={phoneNumber}
                    /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Company *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="company"
                      placeholder="Company"
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                      value={company}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-3 col-md-12 text-center">
                <button
                  className="btn btn-primary profile-button"
                  style={{ backgroundColor: "#5746ec" }}
                  type="button"
                  onClick={handelSubmit}
                  id="profile"
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Save Profile
                    </>
                  ) : (
                    "Save Profile"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
};

export default UserProfileForm;
