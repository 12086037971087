const ENV = {
  // BASE_URL: "http://localhost:8081/api",
  // BASE_URL_PLAT: "http://localhost:3200",
  // Production
  // BASE_URL: "https://api.cloud.nextyn.com/api",
  // BASE_URL_PLAT: "https://api.platform.nextyn.com",
  // development
  BASE_URL: "https://dev.api.cloud.nextyn.com/api",
  BASE_URL_PLAT: "https://dev.api.platform.nextyn.com",
};

export default ENV;
