import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createSearchParams, NavLink, useLocation } from "react-router-dom";
import AddMemberModal from "../components/AddMemberModal";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import { BsChevronLeft } from "../Utils/Icons";
import NextynLi from "./NextynLi";
import ProjectScopeLeftSection from "./ProjectScopeLeftSection";
import ScopeOverview from "./ScopeOverview";
import ScopeProjectInfomation from "./ScopeProjectInfomation";
import ScopeTeamsli from "./ScopeTeamsli";

function ProjectsDetails() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");

  const [Loading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem("userData"));

  console.log(userData.user.role_id.projects, "userData");

  const [projectDetails, setProjectDetails] = useState();
  const [clientTeam, setClientTeam] = useState({});
  const [nextynTeam, setNextynTeam] = useState();
  const [projectinfo, setProjectinfo] = useState({});

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };
  const getClientProjectDetails = () => {
    setLoading(true);
    axios
      .get(
        `${API_PATH.CLIENT_PROJECT_DETAILS}/${project_id}`,

        { headers }
      )
      .then((res) => {
        // console.log(res);
        // localStorage.setItem(
        //   "projectDetails",
        //   JSON.stringify(res.data.details.project_title)
        // );
        setProjectDetails(res?.data?.data);
        setClientTeam(res?.data?.clientTeam);
        setNextynTeam(res?.data?.nextynTeam || []);
        setProjectinfo(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.messagex);
      });
  }
  useEffect(() => {
    getClientProjectDetails()
  }, []);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  console.log("nextynTeam", nextynTeam);
  console.log("projectinfo", projectinfo);

  // console.log("projectDetails", projectDetails);
  console.log("clientTeam", clientTeam);

  // console.log("clientTeam", clientTeam);
  // console.log("projectinfo", projectinfo);

  // console.log("projectDetails", projectDetails);
  //   console.log("clientTeam", clientTeam[0]);

  document.title = "Client | Projects Details";

  if (projectDetails?.status === false) {
    alert("The action you have requested is not allowed.");
    window.location = "/projects";
    return;
  }
  if (Loading) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  } else
    return (
      <>
        <MobileMenu />
        <div className="page-wrapper chiller-theme toggled">
          <div className="main-sec d-lg-flex">
            <div className="sidebar-menu" id="navAccordion">
              <Sidebar />
            </div>
          </div>
          <main className="body-total content-wrapper float-start w-100">
            <div className="container-fluid">
              {/* <div className="d-block d-lg-block">
                <div className="top-bar-menu float-start">
                  <TopMenu2 />
                </div>
              </div> */}

              <div className="dasb-div bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope" style={{ paddingTop: "0px" }}>
                  <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                    <h4 className="d-flex align-items-center">
                      <NavLink to="/projects" style={{ textAlign: "left" }}>
                        <BsChevronLeft className="me-3 search-btn-back" />
                      </NavLink>
                      Project Scope
                    </h4>

                    <div className="d-flex justify-content-center align-items-center">
                      <ul
                        className="nav nav-pills mb-3 mt-4"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <NavLink
                            to={{
                              pathname: "/projects-details",
                              search: `?${createSearchParams({ project_id })}`,
                            }}
                            className="nav-link "
                          >
                            Overview
                          </NavLink>
                        </li>
                        {userData?.user?.role_id?.projects?.message === true ? (
                          <>
                            <li className="nav-item" role="presentation">
                              <NavLink
                                to={{
                                  pathname: "/projects-details-message",
                                  search: `?${createSearchParams({
                                    project_id,
                                  })}`,
                                }}
                                className="nav-link"
                              >
                                Messages
                              </NavLink>
                            </li>
                          </>
                        ) : null}

                        {userData?.user?.role_id?.projects?.expertList ===
                          true ? (
                          <>
                            <li className="nav-item" role="presentation">
                              <NavLink
                                to={{
                                  pathname: "/projects-details-expert",
                                  search: `?${createSearchParams({
                                    project_id,
                                  })}`,
                                }}
                                className="nav-link"
                              >
                                Experts
                              </NavLink>
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>

                    <div>
                      <div style={{ color: "#F8F8F8" }}>Create Project</div>
                    </div>
                  </div>

                  <span className="sp-hr w-100" />

                  <div className="request-body-sec projects-details-page">
                    <div className="d-md-flex justify-content-between">
                      <div className="left-section-divs">
                        <ProjectScopeLeftSection datas={projectDetails} />
                      </div>

                      <div className="right-section-div">
                        <div className="top-right-projects mt-4 mt-md-0">
                          <h2> Project Overview </h2>
                          <ScopeOverview datas={projectDetails} />
                        </div>

                        <div className="middle-right mt-4">
                          <h2 className="my-0"> Project Information </h2>
                          <ScopeProjectInfomation datas={projectinfo} />
                        </div>

                        <div className="client-temas-div mt-4">
                          <div className="d-flex justify-content-between">
                            <h2 className="my-0"> Client </h2>
                            <AddMemberModal ID={project_id} getClientProjectDetails={getClientProjectDetails} />
                          </div>
                          <ScopeTeamsli datas={clientTeam} id={project_id} getClientProjectDetails={getClientProjectDetails} />
                        </div>

                        <div className="client-temas-div mt-4">
                          <div className="d-flex justify-content-between">
                            <h2 className="my-0"> Nextyn </h2>
                          </div>
                          <NextynLi
                            inviteTeamDashboard={nextynTeam}
                            project_id={project_id}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div
          className="modal fade projects-modal"
          id="expertmodal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="projects-modal-divs">
                  <div className="d-flex position-relative align-items-center">
                    <h2 className="text-center mx-auto">
                      {" "}
                      How many experts would you like to consult?
                    </h2>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-8 p-0 mx-auto d-flex justify-content-center">
                      <a id="#" className="one-ex-btn btn">
                        {" "}
                        One Expert{" "}
                      </a>
                      <a id="#" className="more-ex-btn btn">
                        {" "}
                        More than one{" "}
                      </a>
                    </div>
                    <hr className="col-lg-8 mt-4 mx-auto " />

                    <div className="notes col-lg-8 mx-auto p-0">
                      <span> NOTE: </span>{" "}
                      <span>
                        {" "}
                        If your project requires multiple experts, please click
                        on “More than one” and our consultants will reach out to
                        you to assist with a custom recruitment.{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ProjectsDetails;
