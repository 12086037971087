import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import "./AddMemberModal.css";
import { Button, Col, Modal, Row } from "react-bootstrap";

const AddMemberModal = ({ ID, getClientProjectDetails = () => { } }) => {
  // console.log(ID);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [ModalToggle, setModalToggle] = useState(false);
  const loginDetails = JSON.parse(localStorage.getItem("userData"));

  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...emailList];
    list.splice(index, 1);
    setEmailList(list);
  };

  const handleAddClick = () => {
    setEmailList([...emailList, { email: "" }]);
  };

  const checkEmail = (email) => {
    //let emailData = [];

    //for (var Email in emailList) {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      //setChekemail(true)
      setMessage("");
    } else {
      //console.log("empty")
      //console.log("Email is invalid");
      return setMessage("Email is invalid");
    }
    // }
  };

  const handleSubmit = async () => {
    setSpinner(true);

    let emailData = [];

    for (var Email in emailList) {
      if (emailList[Email].email !== "") emailData.push(emailList[Email].email);
    }

    let payload = {
      project_id: ID,
      invite_team: emailData,
      user_id: loginDetails?.user?._id,
    };

    if (payload.invite_team.length === 0 || emailData.length === 0) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      try {
        await fetch(`${API_PATH.CLIENT_ADD_TEAM_MEMBER}`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginDetails?.token}`,
          },
        });
        setSpinner(false);
        // document.getElementById("exampleModal").click();
        getClientProjectDetails()
        return toast.success("Invited successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

      } catch (error) {
        console.log('error: ', error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <button
        onClick={() => setModalOpen(true)}
        variant="outlined"
        className="btn add-pls"
      >
        {" "}
        <BsPlusLg /> Add{" "}
      </button>

      <Modal
        className="bt_modal"
        centered
        size="lg"
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="mb-0">Add a team member to the project</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="form-group ">
                <div id="addnewEmails">
                  <div
                    className="pos-relative "
                    style={{
                      width: "100%",
                    }}
                  >
                    {emailList.map((x, i) => {
                      return (
                        <div
                          className="box d-flex justify-content-around align-items-center "
                          style={{
                            marginBottom: "10px",
                          }}
                          key={i}
                        >
                          <div className="col-sm-11 my-1">
                            <label
                              className="sr-only"
                              htmlFor="inlineFormInputGroupUsername"
                            >
                              Enter Email
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="inlineFormInputGroupUsername"
                                placeholder="Enter Email"
                                name="email"
                                value={x.email}
                                onChange={(e) => handleInputChangeEmail(e, i)}
                              />
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  {"@" + loginDetails.user.email.split("@")[1]}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="btn-box d-grid">
                            {emailList.length !== 1 && (
                              <DangerousOutlinedIcon
                                className="mr10"
                                onClick={() => handleRemoveClick(i)}
                              />
                            )}
                            {emailList.length - 1 === i && (
                              <AddCircleOutlineIcon onClick={handleAddClick} />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger" style={{ marginLeft: "3%" }}>
                        {message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              {message === "" ? (
                <Button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#5746ec", border: "none" }}
                  onClick={handleSubmit}
                  loading={spinner}
                >
                  Invite
                </Button>
              ) : (
                <Button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#5746ec", border: "none" }}
                  onClick={handleSubmit}
                  disabled
                >
                  Invite
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddMemberModal;
