export const convertToHtmlTranscript = (recordingData) => {
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }
  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "-";
    }
  };
  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }
  return `<div id="scriptBody" style="width: 880px">
  <h6 style="text-align:center;color:#5746ec;font-weight:600">${recordingData?.companyName
    } - ${replaceTxt(recordingData?.topic, [
      "nextyn",
      "micro",
      "consulting",
      "-consulting",
      "call - ",
      "call",
      // "-",
    ])?.toUpperCase()} - ${convertDate(recordingData?.recording_start)}</h6>
  <div style="height:1px; background: #000"></div>
    ${recordingData?.assembly_response?.utterances?.map(
      (value) => `<div style='position:relative;margin-top:20px;'>
                      <div>
                          <h6 style='margin-bottom: 1;display:flex;align-items:center;font-weight:600;color:#000;font-size: 12px'>
                              <strong>Speaker ${value.speaker}</strong>
                              <span style='color:#8294a5;font-size:12px;font-weight:400;display:inline-block;margin-left: 14px'>
                                  ${convertMS(value?.end)}
                              </span>
                          </h6>
                          <p style='font-size:12px;color:#05294b;cursor:pointer';>${value.text
        }</p>
                      </div>
                  </div>`
    )}
    </div>`;
};

export const getModifiedDescription = (description) => {
  let bulletPoints;
  bulletPoints = description?.replaceAll("-", "");
  bulletPoints = description?.split("\n");
  return bulletPoints;
};

export const getMonthName = (monthNum) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const index = parseInt(monthNum, 10) - 1;
  return monthNames[index];
};
export const convertDate = (date) => {
  if (
    date !== null &&
    date !== "" &&
    date !== "null" &&
    date !== "null - " &&
    date !== "null - null"
  ) {
    // Check if the date is in the format "08, 2023 - 09, 2023"
    if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
      const [startDate, endDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "08, 2023 - "
    if (/^\d{2}, \d{4} - $/.test(date)) {
      const [startDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "08, 2023 "
    if (/^\d{2}, \d{4}$/.test(date)) {
      const [startMonthNum, startYear] = date.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "2018-04"
    if (/^\d{4}-\d{2}$/.test(date)) {
      const [year, monthNum] = date.split("-");
      const month = getMonthName(monthNum);
      return `${month}, ${year}`;
    }

    // Check if the date is in the format "10, 2014 - 01, 2020"
    if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
      const [startDate, endDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // Check if the date is in the format "02, 2018   -    "
    if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
      const [startDate] = date.split(" - ");
      const [startMonthNum, startYear] = startDate.trim().split(", ");
      const startMonth = getMonthName(startMonthNum);
      return `${startMonth}, ${startYear}`;
    }

    // For other formats, convert the date to the desired format
    var d = new Date(date);
    var day = d.getDate();
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    let formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  } else {
    return "";
  }
};

export const getFormattedDate = (date) => {
  if (!date || typeof date !== "string") {
    return null;
  }

  const dates = date.split(" - ");
  const startDate = convertDate(dates[0]?.trim(), "month");
  const endDate = dates[1] ? convertDate(dates[1]?.trim(), "month") : "";

  return `${startDate} - ${endDate}`;
};
export const statusSetter = (status) => {
  if (status === "Edit") {
    return (
      <p className="mb-0">
        Are you sure you want to schedule a call with this expert? Once you
        confirm, our team will get in touch with you to lock in a convenient day
        and time for the same.{" "}
      </p>
    );
  } else if (status === "Request") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Requested?
      </p>
    );
  } else if (status === "Schedule") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Scheduled?
      </p>
    );
  } else if (status === "Complete") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as completed?
      </p>
    );
  } else if (status === "Decline") {
    return (
      <p className="mb-0">
        Are you sure, you want to mark this call as Declined?
      </p>
    );
  } else if (status === "Delete") {
    return (
      <p className="mb-0">Are you sure, you want to delete this expert?</p>
    );
  }
};

export const statusButton = (status) => {
  if (status === "Edit" || status === "Edit") {
    return "Edit";
  } else if (status === "Request") {
    return "Request";
  } else if (status === "Schedule") {
    return "Schedule";
  } else if (status === "Complete") {
    return "Complete";
  } else if (status === "Decline") {
    return "Decline";
  } else if (status === "Delete") {
    return "Delete";
  }
};
//   if (!data?.expert_experience || data.expert_experience.length === 0) {
//     return [];
//   }

//   const experienceList = data.expert_experience.map((experience) => {
//     const obj = {};
//     obj.previous_designation = experience.previous_designation || "";
//     obj.previous_compny = experience.previous_compny || "";
//     obj.previous_date = getFormattedDate(
//       experience.previous_designation_date
//     );

//     // Filter out entries with invalid or empty previous_date
//     if (!obj.previous_date || obj.previous_date === "NaN undefined, NaN") {
//       return null;
//     }

//     return obj;
//   });

//   // Filter out null entries to remove the invalid data
//   const filteredExperienceList = experienceList.filter(
//     (item) => item !== null
//   );

//   return filteredExperienceList;
// };

export const modifiedContent = (cntnt) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(cntnt, "text/html");

  // Find all anchor tags within the parsed document
  const anchors = doc.querySelectorAll("a");

  // Add the target="_blank" attribute to each anchor tag
  anchors.forEach((anchor) => {
    let href = anchor.getAttribute("href");

    // Check if href doesn't start with 'https'
    if (href && !href.startsWith("https")) {
      // If href starts with 'http', replace with 'https'
      if (href.startsWith("http")) {
        href = href.replace(/^http:/, "https:");
      }
      // If href doesn't start with any protocol, add 'https://'
      else if (!href.startsWith("//") && !href.startsWith("/")) {
        href = `https://${href}`;
      }

      // Set the modified href back to the anchor tag
      anchor.setAttribute("href", href);
    }

    // Set target="_blank" to open the link in a new tab
    anchor.setAttribute("target", "_blank");
  });

  cntnt = doc.body.innerHTML;

  return cntnt;
};
